import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex power" }

const targetPath = '';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const origin =
  process.env.VUE_APP_ENV === 'production'
    ? 'authority.ws.lingdong.cn'
    : 'test-authority.ws.lingdong.cn';
const target = `https://${origin}/myAuth/apply?sid=${process.env.VUE_APP_SID}`;
const onClickApplyRight = () => {
  window.open(target, '_self');
};

return (_ctx: any,_cache: any) => {
  const _component_e_button = _resolveComponent("e-button")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title tip-color" }, "暂无权限", -1)),
        _createVNode(_component_e_button, {
          type: "text",
          onClick: onClickApplyRight
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("申请权限")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})